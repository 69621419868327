import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons/faTachometerAlt";

import AdminLayout from "Pages/Admin/AdminLayout";

import { ModalTrigger } from "Components/Modal";
import { Loading } from "Components/Partials";
import { Select } from "Components/Form";
import ViewAverageHistory from "./ViewAverageHistory";

import HolidayDashboardApi from "Services/Api/Admin/HumanResources/HolidayDashboard";
import { Toast } from 'Services';
import { convertDecimalToTime } from "Services/BaseHelpers";

class Dashboard extends React.Component {
    /**
     * @var state
     * @type {{loading: boolean}}
     */
    state = {
        loading: true,
        holidays: [],
        holiday_year: this.getHolidayYear(),
    };

    /**
     * @var minYear
     * @type {number}
     */
    minYear = 2023;

    /**
     * @var years
     */
    years = [...Array(new Date().getFullYear() - this.minYear + 2).keys()].map(i => ({
        value: this.minYear + i,
        label: this.minYear + i,
    }));

    /**
     * @method getHolidayYear
     * @returns {number}
     */
    getHolidayYear() {
        const now = new Date();
        const currentYear = now.getFullYear();
        return now < new Date(currentYear, 3, 31) ? currentYear - 1 : currentYear;
    }

    /**
     * @method componentDidMount
     */
    componentDidMount = () => {
        this.fetchHolidayData();
    };

    /**
     * @method componentDidUpdate
     * @param _
     * @param prevState
     */
    componentDidUpdate(_, prevState) {
        if (prevState.holiday_year !== this.state.holiday_year) {
            this.fetchHolidayData();
        }
    };

    /**
     * @method fetchHolidayData
     */
    fetchHolidayData = async () => {
        this.setState({ loading: true });

        //add one year to the selected year
        let year = this.state.holiday_year + 1;


        const request = await HolidayDashboardApi.get(null, {
            year: year,
        });

        if (!request.success) {
            return Toast.error('Failed to fetch holiday data');
        }

        this.setState({
            loading: false,
            holidays: request.data.data ?? [],
        });
    };

    /**
     * @method render
     * @returns {JSX.Element}
     */
    render () {
        const { loading } = this.props;
        const { holiday_year, holidays } = this.state;

        return (
            <AdminLayout title="Holiday Dashboard" titleIcon={faTachometerAlt}>
                <div className="px-4">
                    <Select
                        className="mb-4"
                        label="Holiday Year"
                        options={this.years}
                        value={holiday_year}
                        onChange={holiday_year => this.setState({ holiday_year })}
                    />

                    {loading && (
                        <Loading />
                    )}

                    {!loading && (
                        <div className="overflow-x-auto">
                            <table className="table-auto mx-auto">
                                <thead>
                                <tr className="bg-gray-300">
                                    <th className="px-4 py-2 border border-app-leading text-center">Name</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Allowance</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Days per Week</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Hours per one day of A/L</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Taken</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Remaining</th>
                                </tr>
                                </thead>

                                <tbody>
                                {holidays?.map((holiday, index) => (
                                    <tr key={`holiday-${index}`}>
                                        <td className="px-4 py-2 border border-app-leading text-center">{holiday?.full_name}</td>
                                        <td className="px-4 py-2 border border-app-leading text-center">{holiday.holiday_allowance}</td>
                                        <td className="px-4 py-2 border border-app-leading text-center">{holiday.days_per_week}</td>
                                        <td className="px-4 py-2 border border-app-leading text-center">{convertDecimalToTime(holiday.hours_per_day)}</td>
                                        <td className="px-4 py-2 border border-app-leading text-center">{holiday.holiday_taken_days} ({convertDecimalToTime(holiday.holiday_taken_hours)})</td>
                                        <td className="px-4 py-2 border border-app-leading text-center">{holiday.holiday_remaining}</td>
                                        <td className="px-4 py-2 border border-app-leading text-center">
                                            {holiday.average_history?.length > 0 && (
                                                <ModalTrigger
                                                    component={ViewAverageHistory}
                                                    props={{
                                                        holiday,
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faEye} className="cursor-pointer" />
                                                </ModalTrigger>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </AdminLayout>
        );
    }
}

export default Dashboard;
